import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

import BadgeCard from '@components/profile/BadgeCard';
import LatestResults from '@components/profile/LatestResults';
import ArenaCard from '@components/ArenaCard';
import PlayerAvatar from '@components/PlayerAvatar';
import UserContext from '@components/login/UserContext';
import SeasonNumber from '@components/SeasonNumber';
import EditableAvatar from '@components/profile/EditableAvatar';

import { useProfile } from '@apis/Players';
import { Player } from '@apis/Types';

import './PlayerProfile.css';

function IfpaLink({ ifpaId }) {
    if (ifpaId) {
        return <Link href={`https://www.ifpapinball.com/player.php?p=${ifpaId}`}>View IFPA Profile</Link>;
    }

    return null;
}

function MatchplayLink({ matchplayId }) {
    if (matchplayId) {
        return <Link href={`https://matchplay.events/live/users/${matchplayId}`}>View Matchplay Profile</Link>;
    }

    return null;
}

function PlayerCard({ player, rank }) {
    const { user } = useContext(UserContext);

    if (!player) {
        return (
            <Box sx={{ pt: 0.5 }}>
                <Skeleton variant="rectangular" width={210} height={118} />
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
        );
    }

    const userPlayer = user?.player as Player;
    return (
        <>
            <div className="profile-card">
                {userPlayer?._id === player._id ? (
                    <EditableAvatar />
                ) : (
                    <PlayerAvatar player={player} variant="rounded" hideBadgeCount sx={{ height: 96, width: 96 }} />
                )}

                <Typography className="profile-name" sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    {player.name}
                </Typography>
            </div>
            <Chip label={rank ? `Rank ${rank}` : 'Unranked'} color="secondary" />
            <IfpaLink ifpaId={player.ifpaId} />
            <MatchplayLink matchplayId={player.matchplayId} />
            <Divider />
            <Typography variant="h6">Seasons Participated In</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {player.seasons.map((sn) => (
                    <SeasonNumber key={sn} number={sn} />
                ))}
            </Box>
        </>
    );
}

const ARENA_COLUMNS = [
    {
        label: 'Low Score',
        property: 'low',
    },
    {
        label: 'Average Score',
        property: 'average',
    },
    {
        label: 'High Score',
        property: 'high',
    },
];

export default function PlayerProfile() {
    const { id } = useParams();
    const { player, badges, results, arenas, rank } = useProfile(id);

    return (
        <Grid container spacing={2} sx={{ overflowX: 'auto', marginLeft: '-8px' }}>
            <Grid item sm={12} md={4} sx={{ padding: '10px', flexGrow: 1 }}>
                <Paper elevation={3} sx={{ marginRight: '12px' }}>
                    <Stack spacing={2} sx={{ padding: '20px' }}>
                        <PlayerCard player={player} rank={rank} />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item sm={12} md={8} sx={{ padding: '10px' }}>
                <Paper elevation={3} sx={{ marginRight: '12px' }}>
                    <BadgeCard badges={badges} />
                </Paper>
                <Paper elevation={3} sx={{ marginRight: '12px', marginTop: '12px' }}>
                    <LatestResults className="latestResults" results={results} />
                </Paper>
                <Paper elevation={3} sx={{ marginRight: '12px', marginTop: '12px' }}>
                    <ArenaCard fixed arenas={arenas} columns={ARENA_COLUMNS}></ArenaCard>
                </Paper>
            </Grid>
        </Grid>
    );
}
